import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of, switchMap, take } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  appendTokenToUrl,
  AuthInterceptorConfig,
  getAuthToken,
  SERVICE_TOKEN_INTERCEPTOR_CONFIG,
  ServiceAccessTokenProvider,
  ServiceTokenInterceptorConfig
} from '@celum/authentication';
import { appendUrlSearchParam } from '@celum/core';

import { QueryParamTokensProviderService } from './query-param-tokens-provider.service';
import { CONFIGURATION_SERVICE, ConfigurationService } from '../model/configuration.service';
import { EDIT_MODE } from '../util/portal-injection-tokens';

@Injectable()
export class AuthTokenService {
  constructor(
    private serviceAccessTokenProvider: ServiceAccessTokenProvider,
    @Inject(CONFIGURATION_SERVICE) private configurationService: ConfigurationService,
    @Inject(SERVICE_TOKEN_INTERCEPTOR_CONFIG) private interceptorConfig: ServiceTokenInterceptorConfig<AuthInterceptorConfig>,
    @Inject(EDIT_MODE) private editMode: boolean,
    @Optional() private tokenProviderService: QueryParamTokensProviderService
  ) {}

  public fetchAndAppendTokenToUrl(url: string): Observable<string> {
    if (!url) {
      return of(url);
    }

    const tokens = this.tokenProviderService?.getTokens();
    if (tokens) {
      let urlWithTokens = appendUrlSearchParam(url, 'token', tokens.b2cToken);
      urlWithTokens = appendUrlSearchParam(urlWithTokens, 'portalReadToken', tokens.portalReadToken);
      return of(urlWithTokens);
    }

    return this.getToken(url).pipe(map(token => appendTokenToUrl(url, token)));
  }

  public getToken(url: string, tokenLeadTimeInMilliseconds?: number): Observable<string> {
    if (!url) {
      console.debug('AuthTokenService: parameter url is missing, cannot determine which token to add.');
      return of(null);
    }

    return this.configurationService.portalConfiguration$.pipe(
      take(1),
      switchMap(portalConfig => {
        if (portalConfig.protected || this.editMode) {
          return getAuthToken(url, this.interceptorConfig, this.serviceAccessTokenProvider, tokenLeadTimeInMilliseconds);
        } else {
          return of(undefined);
        }
      })
    );
  }
}
